<template>
  <v-card>
    <v-toolbar
      dense
      card
    >
      <v-icon :color="color">
        {{ icon }}
      </v-icon>
      <v-toolbar-title>Update {{ value.msisdn }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container fluid>
          <v-layout column>
            <!-- <v-flex
              v-if="donor"
              xs12
            >
              <mnp-donors v-model="donor" />
            </v-flex> -->
            <v-flex xs12>
              <iccid
                v-model="iccid"
                :rules="iccidRules"
                hint="Locate serial number on the simpack"
                label="Enter serial number"
                required
              />
            </v-flex>
            <!-- <v-flex
              v-if="!value.resubmit"
              xs12
            >
              <black-packages v-model="blackPackage" />
            </v-flex> -->
            <!--v-flex v-if="price" xs12>
              <v-text-field
                hint="Mark-up from bid price recommended"
                label="Enter Msisdn price"
                :mask="simMask"
                required
                v-model="sellingPrice">
              </v-text-field>
            </v-flex-->
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click.native="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click.native="updateMsisdn"
      >
        Update
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { spIcon } from '@/utils'
import { createGetParams } from '@/rest'

export default {
  components: {
    // BlackPackages: () => import(/* webpackChunkName: "blackPackages" */ '@/components/BlackPackages'),
    // MnpDonors: () => import(/* webpackChunkName: "mnpDonors" */ '@/components/MnpDonors'),
    Iccid: () => import(/* webpackChunkName: "iccid" */ '@/components/Iccid'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      // blackPackage: {},
      chargeable: 0.00,
      color: null,
      // donor: null,
      iccid: null,
      iccidRules: [val => !!val || 'Iccid required!'],
      icon: null,
      inputError: null,
      // price: null,
      sellingPrice: 0.00,
      /*
      simMask: {
        blockSeparator: ' ',
        blockSize: 3,
        formatter: 'numeral',
        positiveOnly: true,
        precision: 2,
        prefix: 'RM '
      },
      */
      source: null,
      valid: true,
    }
  },
  watch: {
    dialog: function (val) {
      // this.blackPackage = Object.assign({}, this.value.plan)
      this.iccid = this.value.iccid
      this.color = this.value.iconClass
      this.icon = this.value.icon
      this.price = this.value.price
      this.sellingPrice = this.value.sellingPrice
      this.chargeable = this.value.chargeable
    },
    'item.iccid': function (val) {
      this.cancelRequest()
      if (this.icon !== spIcon || this.iccid.length < 18) return
      this.validateIccid()
    },
  },
  mounted: function () {
    if (this.value.resubmit) { this.iccidRules = null } else { this.iccidRules.push(val => !this.inputError || this.inputError) }
  },
  methods: {
    cancelRequest: function () {
      this.inputError = null
      if (this.source) this.source.cancel('Forced cancellation.')
      this.source = null
    },
    closeDialog: function () {
      this.$emit('update:dialog', false)
      this.inputError = null
      this.source = null
    },
    updateMsisdn: function () {
      if (!this.$refs.form.validate()) return
      const localValue = this.value
      // Object.assign(localValue.plan, this.blackPackage)
      localValue.iccid = this.iccid
      // localValue.sellingPrice = this.sellingPrice
      // localValue.chargeable = Number(localValue.sellingPrice) + getNumeralFromBlackCode(localValue.plan.code)
      // localValue.detail = localValue.msisdn + ', ' + localValue.plan.code
      // if (this.donor) localValue.detail += ', ' + localValue.donor
      this.$emit('input', localValue)
      this.closeDialog()
    },
    validateIccid: function () {
      const params = createGetParams({
        iccid: this.item.iccid,
      })

      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.$rest.get('validateIccid.php', params)
        .then(response => {
          const msisdn = String(response.data)
          if (!msisdn || msisdn.indexOf(this.value.msisdn) === -1) {
          // if (this.value.msisdn !== response.data.msisdn) {
            this.inputError = 'SP details mismatched!'
          }
        }).catch(e => {
          if (this.$rest.isCancel(e)) return

          if (e.response && e.response.status === 404) {
            this.inputError = 'No such Iccid!'
          } else {
            this.inputError = e.message
          }
        })
    },
  },
}
</script>
